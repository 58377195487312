import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  KycState,
  OnboardingStateService,
} from '../services/onboarding-state.service';

@Component({
  selector: 'bvn-verification',
  template: `
    <div class="flex-col" *ngIf="!showVerification">
      <h3 class="fs-18 fw-600 mb-0 dark-01">Enter BVN</h3>
      <p class="fs-14 mt-0 grey-02 mb-5">
        Please enter your bank verification number (BVN)
      </p>

      <form [formGroup]="bvnForm" id="bvn-form" (ngSubmit)="submit()">
        <mat-form-field class="w-full" appearance="outline">
          <mat-label>Enter BVN</mat-label>
          <input
            minlength="11"
            maxlength="11"
            formControlName="bvn"
            matInput
            #input
            type="text"
          />
          <mat-hint align="end">{{ input.value?.length || 0 }}/11</mat-hint>
          <mat-error *ngIf="bvnForm.get('bvn')?.errors?.pattern"
            >You can only input numbers.</mat-error
          >
        </mat-form-field>
      </form>

      <p class="fs-12 mt-5 text-center">
        Read more about our
        <a
        routerLink="/privacy"
          class="primary-color"
          >Privacy Policy</a
        >
      </p>
      <div class="flex flex-col">
        <mat-progress-bar
          *ngIf="kycState.loading"
          mode="indeterminate"
        ></mat-progress-bar>
        <button
          form="bvn-form"
          type="submit"
          [disabled]="!bvnForm.valid || kycState.loading"
          class="cbtn cbtn-primary fs-16 py-3 px-20 mt-2"
        >
          Connect BVN
        </button>
        <button
    (click)="kyc.nextTab(1)"
    class="cbtn cbtn-plain fs-16 py-3 px-20 mt-2">
Back
  </button>
      </div>
    </div>
    <bvn-bank-verification *ngIf="showVerification" (backButton)="showVerification = false" ></bvn-bank-verification>
  `,
})
export class BvnVerificationComponent implements OnInit {
  @Input() kycState: KycState = {} as KycState;
  bvnForm!: FormGroup;
  showVerification: boolean = false;
  consent: FormControl = new FormControl('', Validators.required);

  constructor(public kyc: OnboardingStateService) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.bvnForm = new FormGroup({
      bvn: new FormControl('', [
        Validators.minLength(11),
        Validators.maxLength(11),
        Validators.required,
        Validators.pattern('^[0-9]{11}$'),
      ]),
    });
  }

  submit(): void {
    this.kyc.updateStateBvn(this.bvnForm.controls.bvn.value);
    this.showVerification = true
  }
}
