<div class="flex-col">
  <h3 class="fs-18 fw-600 mb-0 dark-01">Enter Payout Account</h3>
  <p class="fs-14 mt-0 grey-02 mb-5">
    Enter the bank account you'll like to withdraw<br />your funds into
  </p>
  <form [formGroup]="bankForm" *ngIf="banks.state | async as state">
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Bank</mat-label>
      <mat-select
        (change)="getAccountName()"
        formControlName="bankId"
        placeholder="Choose Bank"
      >
        <mat-option *ngFor="let item of state.banks" [value]="item.id">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Account Number</mat-label>
      <input
        (change)="getAccountName()"
        minlength="10"
        maxlength="10"
        formControlName="bankAcctNo"
        matInput
        #input
        inputmode="numeric"
        pattern="[0-9]*"
        type="text"
      />
      <mat-hint align="end">{{ input.value?.length || 0 }}/10</mat-hint>
      <mat-error *ngIf="bankForm.get('bankAcctNo')?.errors?.pattern"
        >You can only input numbers.</mat-error
      >
    </mat-form-field>
    <div class="flex-col my-2">
      <mat-progress-bar
        *ngIf="state.loading"
        mode="indeterminate"
      ></mat-progress-bar>
    </div>
    <button
      *ngIf="state.error"
      (click)="getAccountName()"
      class="cbtn cbtn-primary cbtn-md w-full"
    >
      Retry
    </button>
    <p class="text-right primary-color">
      {{ state.customerInfo?.accountName }}
    </p>
  </form>

  <p class="fs-12 my-2">
    <input type="checkbox" [formControl]="consent" id="consent" />
    <label for="consent" class="pl-2"
      >I consent that Chaka should verify my bank details</label
    >
  </p>
  <p class="fs-12 mt-5 text-center">
    Read more about our
    <a routerLink="/privacy" class="primary-color">Privacy Policy</a>
  </p>
  <div class="flex flex-col">
    <mat-progress-bar
      *ngIf="kycState.loading"
      mode="indeterminate"
    ></mat-progress-bar>
    <button
      (click)="kyc.nextTabNoIndex()"
      [disabled]="formValidity"
      class="cbtn cbtn-primary fs-16 py-3 px-20 mt-2"
    >
      Connect Bank Account
    </button>
    <button
      (click)="backButton.emit()"
      class="cbtn cbtn-plain fs-16 py-3 px-20 mt-2"
    >
      Back
    </button>
  </div>
</div>
