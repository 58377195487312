<div class="px-4 py-3 lg:px-9 lg:py-6 flex justify-between">
  <img alt="Chaka Logo" src="https://res.cloudinary.com/chakasksk/image/upload/v1630774255/Chaka_Logo_vgftxd.svg" />
  <div class="flex items-center self-end justify-end">
    <button (click)="closeApp()" class="cbtn cbtn-danger px-0 py-0 mx-0">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="kyc.state | async as state" class="min-h-screen">
  <div class="flex w-full justify-content-center kyc-tab-container mx-auto px-2">
    <div *ngIf="auth.state | async as authState" class="kyc-grid w-full">
      <div class="kyc-grid-item hidden lg:block">
        <div class="sction-container">
          <div *ngFor="let item of sectionItems" [ngClass]="state.section == item.toUpperCase() ? 'section-item-active' : ''" class="section-item">{{ item }} Verification</div>
        </div>
      </div>
      <div *ngIf="!authState.loading" class="kyc-grid-item">
        <div *ngIf="steps.state | async as stepState" class="kyc-card w-full">
          <mat-tab-group [(selectedIndex)]="state.selectedTab">
            <mat-tab *ngIf="stepState.enabledCats.general && state.selectedTab === 0">
              <ng-template matTabContent>
                <bvn-verification [kycState]="state"></bvn-verification>
              </ng-template>
            </mat-tab>
            <mat-tab *ngIf="stepState.enabledCats.general && state.selectedTab === 1">
              <ng-template matTabContent>
                <bank-verification [kycState]="state"></bank-verification>
              </ng-template>
            </mat-tab>
            <mat-tab *ngIf="stepState.enabledCats.general && state.selectedTab === 2">
              <ng-template matTabContent>
                <dob-verification [kycState]="state"></dob-verification>
              </ng-template>
            </mat-tab>
            <mat-tab *ngIf="stepState.enabledCats.general && state.selectedTab === 3">
              <ng-template matTabContent>
                <personal-info-verification [kycState]="state"></personal-info-verification>
              </ng-template>
            </mat-tab>
            <mat-tab *ngIf="stepState.enabledCats.global && state.selectedTab === 4">
              <ng-template matTabContent>
                <document-upload></document-upload>
              </ng-template>
            </mat-tab>
            <mat-tab *ngIf="stepState.enabledCats.global && state.selectedTab === 5">
              <ng-template matTabContent>
                <employment-info-verification [kycState]="state"></employment-info-verification>
              </ng-template>
            </mat-tab>
            <mat-tab *ngIf="stepState.enabledCats.global && state.selectedTab === 6">
              <ng-template matTabContent>
                <investment-info-verification [kycState]="state"></investment-info-verification>
              </ng-template>
            </mat-tab>
            <mat-tab *ngIf="stepState.enabledCats.local && state.selectedTab === 7">
              <ng-template matTabContent>
                <next-kin-info></next-kin-info>
              </ng-template>
            </mat-tab>
            <mat-tab *ngIf="stepState.enabledCats.local && state.selectedTab === 8">
              <ng-template matTabContent>
                <trading-info></trading-info>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>
