<div class="flex-col">
  <h5 class="fs-20 fw-600 mb-0 text-center">Verify Email</h5>
  <p class="fs-16 grey-04 text-center">
    Please enter the 6 digit code we sent to your email
  </p>
  <div class="my-10">
    <ng-otp-input
      (onInputChange)="otpChange($event)"
      [config]="otpConfig"></ng-otp-input>
  </div>
  <div class="my-4 flex justify-center">
    <button class="cbtn cbtn-plain" (click)="kycState.sendEmailOtp()">
      Resend Code
    </button>
  </div>
  <div class="form-group" >
    <mat-progress-bar
      *ngIf="(kycState.state | async)?.loading"
      mode="indeterminate"></mat-progress-bar>
    <button
      type="submit"
      class="cbtn cbtn-primary w-full my-3 py-4"
      [disabled]="!otpControl.valid"
      (click)="kycState.validateOtp(otpControl.value)">
      Verify Code
    </button>
  </div>
</div>
