import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { KycState, OnboardingStateService } from '../services/onboarding-state.service';
import { VerificationChoiceEnum } from '../services/onboarding.interface';

@Component({
  selector: 'dob-verification',
  templateUrl: './dob-verification.component.html',
})
export class DobVerificationComponent implements OnInit {
  @Input() kycState: KycState = {} as KycState;
  maxDate!: Date;
  choiceEnum = VerificationChoiceEnum;
  dateControl!: FormControl;

  constructor(
    private datePipe: DatePipe,
    public kyc: OnboardingStateService,
  ) {}

  get formValidity(): boolean {
    return this.dateControl.invalid || this.kyc.state.value.loading;
  }

  ngOnInit(): void {
    let validDate = new Date();
    this.maxDate = new Date(validDate.getUTCFullYear() - 18, validDate.getMonth(), validDate.getUTCDate());
    this.dateControl = new FormControl('', [Validators.required, this.forbiddenDate.bind(this)]);
  }

  forbiddenDate(control: FormControl): { invalidDate: boolean } | null {
    if (new Date(control.value) > this.maxDate) {
      return { invalidDate: true };
    }
    return null;
  }

  submit() {
    let state = this.kyc.state.getValue();
    let dob;
    if (state.verificationChoice === this.choiceEnum.PAYOUT_ACCOUNT) {
      dob = this.datePipe.transform(this.dateControl.value, 'dd/MM/yyyy') as string;
    } else {
      dob = this.datePipe.transform(this.dateControl.value, 'dd-MMM-yyyy') as string;
    }
    this.kyc.submitDOB(dob);

    // this.kyc.nextTabNoIndex();
  }
}
