import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { KycState, OnboardingStateService } from '../services/onboarding-state.service';
import { expOptions, fundingSourceOptions, incomeOptions, liquidNwOptions, option, riskOptions, termOptions } from './investment.options';
import { CompletedInvestmentVerificationModalService } from './completed-investment-info-verification/completed-investment-verification-modal.service';
import { DownloadStateService } from '../services/download-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VerificationTypes } from '../services/onboarding.interface';
import { MissingKycStepService } from '../services/missing-kyc-step.service';

@Component({
  selector: 'investment-info-verification',
  templateUrl: './investment-info-verification.component.html',
  styleUrls: ['./investment-info-verification.component.scss'],
})
export class InvestmentInfoVerificationComponent implements OnInit {
  @Input() kycState: KycState = {} as KycState;
  investmentTerms: option[] = termOptions;
  experienceOpts: option[] = expOptions;
  incomeOpts: option[] = incomeOptions;
  liquidOpts: option[] = liquidNwOptions;
  riskOpts: option[] = riskOptions;
  fundingSrcOpts: option[] = fundingSourceOptions;
  investForm: FormGroup;

  questions: {
    question: string;
    options: option[];
    controlName: string;
  }[] = [
    {
      question: 'What Is Your Investment Objective?',
      options: [...this.investmentTerms],
      controlName: 'investmentObjective',
    },
    {
      question: 'What Is Your Investment Experience?',
      options: [...this.experienceOpts],
      controlName: 'investmentExperience',
    },
    {
      question: 'What Is Your Funding Source?',
      options: [...this.fundingSrcOpts],
      controlName: 'fundingSource',
    },
    {
      question: 'What Is Your Annual Income?',
      options: [...this.incomeOpts],
      controlName: 'annualIncome',
    },
    {
      question: 'What Is Your Liquid Networth?',
      options: [...this.liquidOpts],
      controlName: 'networthLiquid',
    },
    {
      question: 'What Is Your Networth Total?',
      options: [...this.liquidOpts],
      controlName: 'networthTotal',
    },
    {
      question: 'What Is Your Risk Tolerance?',
      options: [...this.riskOpts],
      controlName: 'riskTolerance',
    },
  ];

  constructor(
    public kyc: OnboardingStateService,
    private modal: CompletedInvestmentVerificationModalService,
    private gtm: GoogleTagManagerService,
    public downloadStateService: DownloadStateService,
    private _snackBar: MatSnackBar,
    public missingKycStepService: MissingKycStepService,
  ) {
    let { investmentObjective, investmentExperience, annualIncome, networthLiquid, riskTolerance, networthTotal, fundingSource, acceptNoneUsCitizen = false, acceptElectronicSignature = false } = this.kyc.state.getValue().profile;
    this.investForm = new FormGroup({
      fundingSource: new FormControl(fundingSource, Validators.required),
      investmentObjective: new FormControl(investmentObjective, Validators.required),
      investmentExperience: new FormControl(investmentExperience, Validators.required),
      annualIncome: new FormControl(annualIncome, Validators.required),
      networthLiquid: new FormControl(networthLiquid, Validators.required),
      riskTolerance: new FormControl(riskTolerance, Validators.required),
      networthTotal: new FormControl(networthTotal, Validators.required),
      acceptElectronicSignature: new FormControl(acceptElectronicSignature, Validators.pattern('true')),
      acceptNoneUsCitizen: new FormControl(acceptNoneUsCitizen, Validators.pattern('true')),
    });
  }

  get getFormValidity(): boolean {
    return this.investForm.invalid || this.kyc.state.value.loading;
  }

  ngOnInit(): void {
    this.kyc.setSection('GLOBAL');
  }

  setStatus(value: string, formControlName: string) {
    this.investForm.patchValue({
      [`${formControlName}`]: value,
    });
  }

  async handleDownloadW8BenTemplate() {
    this.downloadStateService.updateState({ loading: true });

    const response = await this.downloadStateService.getPdfFileAction('w_8ben_template.pdf');

    response.subscribe(
      value => {
        this.downloadStateService.updateState({ loading: false });

        const fileURL = window.URL.createObjectURL(value);
        const d = new Date();
        const filename = `w8_ben_template-${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}-${d.getTime()}.pdf`;

        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = filename;
        alink.target = '_blank';
        alink.click();
      },
      error => {
        this.downloadStateService.updateState({ loading: false });
        this._snackBar.open(error.message, 'Close');
      },
    );
  }

  submit() {
    const kycMode = this.kyc.state.getValue().verificationMode;

    this.gtm
      .pushTag({
        event: 'GLOBAL_KYC_COMPLETE',
      })
      .catch(console.error);

    if (kycMode == VerificationTypes.GLOBAL || kycMode == VerificationTypes.GENGLOBAL) {
      this.kyc.updateKyc(this.investForm.value, false);

      let cta: string;
      let title: string;

      if (kycMode == VerificationTypes.GENGLOBAL) {
        title = 'You Have Successfully Submitted Your Information For Review';
        cta = 'Close';
      } else {
        title = 'You’re Almost There!';
        cta = 'Complete your Local Verification to start investing in Nigerian stocks.';
      }

      this.modal.openInvestmentInfoModal({
        title,
        cta,
      });

      return;
    }

    this.missingKycStepService.updateState({
      enabledCats: {
        global: false,
        general: false,
        local: true,
      },
    });

    this.kyc.updateKyc(this.investForm.value, true);
  }
}
