<div class="flex-col flex" *ngIf="kyc.state | async as state">
  <h3 class="fs-18 fw-600 mb-0 dark-01 text-center">Verify phone number</h3>
  <p class="fs-14 mt-0 grey-02 mb-3 text-center">
    Enter the verification code that was sent to your<br />
    phone number
    <!-- {{
      state.verificationChoice === choiceEnum.PAYOUT_ACCOUNT
        ? "Bank Account"
        : "BVN"
    }} -->
    <span
      class="primary-color bg-transparent w-2/6 border-0"
      *ngIf="state.profile.phone"
      >{{ state.profile.phone | phoneNumber }}
    </span>
  </p>
  <div
    class="
      flex
      fs-12
      primary-color
      text-center
      mb-3
      justify-center
      items-center
      cursor-pointer
    "
    *ngIf="showPhoneNumber === false; else showPhoneControl"
    (click)="showPhoneNumber = !showPhoneNumber"
  >
    <mat-icon
      style="height: 12px"
      svgIcon="edit-icon"
      class="icon-display"
    ></mat-icon>
    <span class="py-1">Edit phone number</span>
  </div>

  <ng-template #showPhoneControl>
    <form [formGroup]="editPhoneForm">
      <div class="my-10 flex flex-col">
        <phone-country-selector
          (onInput)="receiveNumber($event)"
        ></phone-country-selector>
        <mat-form-field class="w-full mt-2 fs-12" appearance="outline">
          <mat-label>Enter Date of Birth (mm/dd/yyyy)</mat-label>
          <input
            (click)="picker.open()"
            matInput
            formControlName="dob"
            [matDatepicker]="picker"
            [max]="maxDate"
          />
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>today</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <button
          class="cbtn-plain mt-4 fs-12"
          [disabled]="!editPhoneForm.valid"
          (click)="changePhonenumber()"
        >
          Submit
        </button>
      </div>
    </form>
  </ng-template>

  <div class="input-column mb-3">
    <ng-otp-input
      (onInputChange)="otpChange($event)"
      [config]="otpConfig"
    ></ng-otp-input>
  </div>
  <div class="form-group text-center">
    <label class="text-center fs-14 primary-color"  *ngIf="!!(countDown$ | async)">
      {{ countDown$ | async }} Secs To Resend OTP
    </label>

    <button
      *ngIf="showPhoneNumber"
      type="submit"
      class="cbtn-plain w-full my-3"
      [disabled]="!editPhoneForm.valid && !!(countDown$ | async)"
      (click)="changePhonenumber(); this.startOTPDelayTimer();"
    >
      Resend Code
    </button>
    <button
    *ngIf="!showPhoneNumber"
      type="submit"
      class="cbtn-plain w-full my-3"
      [disabled]="!!(countDown$ | async)"
      (click)="kyc.sendPhoneOTP(); this.startOTPDelayTimer();"
    >
      Resend Code
    </button>
  </div>
  <div class="form-group my-6">
    <mat-progress-bar
      *ngIf="state.loading"
      mode="indeterminate"
    ></mat-progress-bar>
    <button
      type="submit"
      class="cbtn cbtn-md cbtn-primary w-full my-3"
      [disabled]="formValidity"
      (click)="kyc.confirmPhoneOTP(otpControl.value)"
    >
      Verify Code
    </button>
  </div>
</div>
